@import "./variables";

.dashboard__view {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: $space-2xs;
  justify-content: center;
  //align-items: center;
  .right {
    background: $color-white;
    border-radius: $border-radius-3;
    box-shadow: $box-shadow;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: $space-m;
    justify-items: center;
    overflow-y: auto;
    max-height: 35rem;
    width: 90%;
    padding: $space-xs;

    .emp__card {
      width: 100%;
      background: $color-light;
      height: $space-3xl;
      border-radius: $border-radius-3;
      display: grid;
      grid-template-columns: 30% 1fr;
      grid-template-rows: 1fr 2fr;
      grid-template-areas:
        " a b"
        "a b";
      padding: $space-xs;
      .top {
        grid-area: a;
        height: 100%;
        display: grid;
        place-items: center;
        img {
          background-color: $color-dark-variant;
          border-radius: 50%;
          height: 5rem;
          width: 5rem;
          background-size: cover;
          object-fit: cover;
        }
      }

      .bottom {
        grid-area: b;
        padding-left: 1rem;
        display: grid;
        align-items: center;
        h3 {
          position: relative;
          font-size: $step-0;
          color: $color-dark;
        }
        h4 {
          font-weight: 300;
          color: $color-info-dark;
          font-size: $step--2;
        }
        small {
          font-size: $step--2;
          color: $color-primary;
          font-weight: 500;
        }
      }
    }
  }
  .left {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 90%;
    padding: 1rem;
    overflow: hidden;
    .starts {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      justify-content: space-between;
      padding: $space-xs $space-m;
      gap: $space-2xs;
      .starts__card {
        width: 100%;
        height: 11rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: $space-xl $space-s auto;
        grid-template-areas:
          "a b c"
          "d e c"
          "f f f";
        box-shadow: $box-shadow;
        background: $color-white;
        padding: $space-xs;

        gap: $space-3xs;
        border-radius: $border-radius-3;
        transition: all 300ms ease;
        .icon__starts {
          grid-area: a;
        }
        h3 {
          grid-area: d;
          color: $color-dark;
        }
        small {
          grid-area: f;
          color: $color-info-dark;
        }
        &:hover {
          box-shadow: none;
        }
        .progress {
          grid-area: c;
          position: relative;
          width: 5rem;
          height: 5rem;
          border-radius: 50%;
        }
      }
    }

    form {
      position: relative;
      padding-top: $space-s;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .search {
        position: relative;
        width: 100%;
        margin: 0 auto;
        background: transparent;
        border-radius: $border-radius-2;
        border: 2px solid $color-info-dark;
        padding: $space-2xs;
      }
      .search__icon {
        position: absolute;
        font-size: $step-3;
        margin-right: $space-2xs;
        color: $color-info-dark;
      }
    }
    .welcome-info {
      display: flex;
      flex-direction: column;
      margin: $space-s 0;
      color: $color-info-dark;
      h2 {
        color: $color-dark;
        font-size: $step-0;
      }
      span {
        font-size: $step--1;
      }
    }
    .users-list {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .user-title {
        display: flex;
        justify-content: space-between;
        margin: $space-xs 0;
        h3 {
          color: $color-dark;
        }
        select {
          outline: none;
          border-radius: $border-radius-1;
          background: transparent;
          color: $color-info-dark;
        }
      }
    }
  }
}
@media (max-width: $breakpoint-tablet) {
  .dashboard__view {
    height: auto;
    width: 100vw;
    grid-template-columns: 1fr;
    overflow: unset;
    .left {
      width: 100%;
      padding: 0;
      .starts {
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        .starts__card {
          width: 80%;
          height: 10rem;
        }
      }
      form {
        width: 95%;
        margin: 0 auto;
      }
      .users-list {
        padding: $space-xs 0;
        width: 90%;
        margin: 0 auto;
      }
    }
    .right {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      gap: $space-xs;
      .emp__card {
        width: 100%;
        height: 8rem;
        //padding-left: $space-2xs;
      }
    }
  }
}
@media (max-width: $breakpoint-mobile) {
  .dashboard__view {
    .left {
      width: 100%;
      padding: 0;
      .starts {
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
        gap: 2rem;
        .starts__card {
          width: 100%;
          height: 10rem;
        }
      }
    }
    .right {
      height: 100% !important;
      display: grid;
      grid-template-columns: 1fr;

      .emp__card {
        width: 80%;
        height: 8rem;
      }
    }
  }
}

// @media (max-width: $breakpoint-ipad) {
//   .dashboard__view {
//     height: 88%;
//     width: 80vw;
//     //justify-content: space-between;

//     .right {
//       grid-template-columns: 1fr 1fr 1fr;
//       gap: 0.5rem;
//       max-height: 25rem;
//     }
//     .left {
//       //justify-content: space-between;
//       padding: 0.5rem;
//       position: relative;
//       height: 40%;
//       padding: 0.5rem;
//       .starts {
//         justify-content: center;
//         gap: 1.5rem;
//         .starts__card {
//           width: 100%;
//           height: 9rem;
//           grid-template-rows: $space-m $space-xs auto;
//           .progress {
//             width: 3.5rem;
//             height: 3.5rem;
//           }
//         }
//       }
//       form {
//         height: 2rem;
//       }
//     }
//   }
// }
