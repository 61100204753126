/* Employees Summary Card Styles */
.employees-summary-card {
    background-color: #ffffff; /* Fondo blanco para el card */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
    padding: 16px;
    margin: 16px 0;
  }
  
  .employees-summary-card h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333333; /* Color del texto */
    margin-bottom: 16px;
  }
  
  .employees-summary-card p {
    color: #666666; /* Color del texto de mensajes */
    position: relative;
  }
  
  .pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
  
  .pagination button {
    background-color: #007bff; /* Color del botón */
    border: none;
    color: #ffffff; /* Color del texto del botón */
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.875rem;
  }
  
  .pagination button:disabled {
    background-color: #cccccc; /* Color para botón deshabilitado */
    cursor: not-allowed;
  }
  
  .pagination button:hover:not(:disabled) {
    background-color: #0056b3; /* Color al pasar el cursor */
  }
  
  /* Week Table Styles */
  .week-table-and-map {
    width: 100%;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  .week-table {
    border-collapse: collapse; /* Elimina el espacio entre las celdas */
    width: 100%;
    .table {
      height: 415px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  
  .week-table h3 {
    font-size: 1.25rem;
    font-weight: 500;
    color: #333333; /* Color del texto */
    margin-bottom: 8px;
  }
  
  .week-table table {
    width: 100%;
    border: 1px solid #dddddd; /* Color de borde de la tabla */
  }
  
  .week-table th, .week-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #dddddd; /* Color de borde inferior */
  }
  
  .week-table th {
    background-color: #f8f9fa; /* Color de fondo de los encabezados */
    color: #333333; /* Color del texto de los encabezados */
    font-weight: 600;
  }

  .week-table .day p:hover {
    cursor: pointer;
  }

  .map-container {
    min-width: 270px;
    height: 450px;
    max-width: 600px;
    width: -webkit-fill-available;
  }

  
  .week-table tr:nth-child(even) {
    background-color: #f2f2f2; /* Color de fila alternante */
  }
  
  .week-table td[rowSpan] {
    background-color: #f9f9f9; /* Color de fondo para celdas con rowSpan */
  }

  .calendar-popup {
    z-index: 1000;
    top: 0;
    right: 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 4px;
    width: 214px;
    height: 232px;
  }

  .custom-datepicker {
      width: 250px; /* Ajusta el ancho si es necesario */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .employees-summary-card {
      padding: 12px;
    }
  
    .employees-summary-card h2 {
      font-size: 1.25rem;
    }
  
    .week-table th, .week-table td {
      padding: 8px;
    }
  
    .pagination button {
      font-size: 0.75rem;
      padding: 6px 12px;
    }
  }
  