//fonts
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "./variables";
// aside section style
@import "./aside";
//header
@import "./header";
//footer
@import "./footer";
//main view
@import "./main";

//register
@import "./register";

//login
@import "./login";

//views
@import "./dashboard";
@import "./admin";
@import "./timecard";
@import "./analytics";

//employeesSummary
@import "./employeesSummary.scss";

//prices
@import "./prices.scss";


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 14px;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
body {
  font-family: "Poppins", sans-serif;
  height: 100vh;
  width: 100vw;
}
#root {
  height: 100%;
  width: 100%;
  background: $color-background;
  overflow-x: hidden;
  user-select: none;
}

main {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 18rem auto;
  overflow: hidden;
}
img {
  display: block;
  width: 100%;
}
.profile-photo {
  width: 2.8rem;
  height: 2.8rem;

  border-radius: 50%;
  overflow: hidden;
}
//font sizes

p {
  color: $color-dark-variant;
}
b {
  color: $color-dark;
}
.primary {
  color: $color-primary;
}
.danger {
  color: $color-danger;
}
.succes {
  color: $color-success;
}
.warning {
  color: $color-warning;
}

::-webkit-scrollbar {
  width: 0.6rem;
  border-radius: 0.3rem;
  background: $color-info-light;
}
::-webkit-scrollbar-thumb {
  background: $color-primary;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

//$breakpoint-tablet: 850px;
@media (max-width: $breakpoint-tablet) {
  main {
    max-width: $breakpoint-tablet;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    // overflow-y: auto;
  }
}

.required:after {
  content: " *";
  color: red;
}

#chart {
  //height: 500px;
  height: 440px;
  color: white;
  // width: 100%;
}

.myReact-icons {
  font-size: $step-4;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  //background: #202528;
  background-image: none;
  color: #202528;
  cursor: pointer;
  //border: 1px solid black;
}
option {
  background: #202528;
  padding-top: 5px;
}

input {
  &:focus {
    border: 1px solid $color-primary !important;
  }
}

@media (max-width: $breakpoint-ipad) {
  .myReact-icons {
    font-size: 2rem;
  }
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fondo negro con 50% de opacidad */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Asegúrate de que esté encima de otros elementos */
}

.loader {
  border: 16px solid #f3f3f3; /* Color de fondo del loader */
  border-top: 16px solid #3498db; /* Color de la parte superior del loader */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); // Fondo oscuro semitransparente
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; // Asegúrate de que el pop-up esté por encima de otros elementos
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 72%;
  max-height: 72%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow-y: auto;
}

.popup-content button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff5e5e;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

/* Estilo para hacer que el pop-up cubra toda la pantalla */
.calendar-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Ensombrecer el resto de la pantalla */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Estilo del pop-up del calendario */
.calendar-popup {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 1000px;
  height: 500px;
}

/* Colorear toda la semana al hacer hover */
.hovered-week {
  background-color: rgba(0, 123, 255, 0.2) !important;
}

/* Estilo para el calendario */
.react-datepicker {
  width: 100% !important;
  height: 100% !important;
}

.react-datepicker__day-names {
  display: flex !important;
  justify-content: space-around !important;
}

.react-datepicker__month-container {
  width: 100% !important;
  height: 100% !important;
}

.react-datepicker__week {
  display: flex !important;
  justify-content: space-around !important;
  height: 60px !important;
  align-items: center !important;
}

.react-datepicker__day {
  width: 100% !important;
  height: 100% !important;
  padding-top: 18px !important;
}

.custom-datepicker .react-datepicker__day--hovered {
  background-color: transparent; /* Desactivar el fondo hover por defecto */
}

/* Estilo para la semana seleccionada */
.selected-week,
.react-datepicker__day--selected {
  background-color: rgba(0, 123, 255, 0.3) !important;
  border-radius: 0 !important;
  color: #000 !important;
  font-weight: normal !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #fff !important;
}

/* Estilo para la semana al hacer hover */
.hovered-week {
  background-color: rgba(0, 123, 255, 0.1) !important; /* Color de fondo azul más claro */
  border-radius: 0 !important;
}
