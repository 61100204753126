@import "./variables";

.timecard__view {
  height: 100%;
  width: 100%;
  display: grid;
  max-width: 100vw;

  grid-template-columns: 1.5fr 1fr;
  overflow: hidden;
  .right {
    // background: grey;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    overflow: hidden;
    .card {
      background: $color-white;
      border-radius: $border-radius-3;
      width: 80%;
      margin: 0.5rem auto;
      height: 18rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      gap: 0.3rem;
      box-shadow: $box-shadow;
      img {
        background-color: $color-info-dark;
        border-radius: 50%;
        width: 5rem;
        height: 5rem;
      }
      h3 {
        font-size: 2rem;
        color: $color-dark;
      }
      .card-info {
        border-radius: $border-radius-1;
        width: 80%;
        display: flex;
        align-items: center;
        gap: 0.2rem;
        :first-child {
          color: $color-dark;
          background: $color-light;
          padding: 0.1rem 0.2rem;
          height: 100%;
        }
      }
    }
    .right-manage {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 1rem;
      padding: 1rem;
      .btn {
        padding: 0.5rem 0.5rem;
        border-radius: $border-radius-3;
        font-weight: 700;

        &:hover {
          transform: scale(1.1);
        }
      }
      .btn-in {
        background: transparent;
        border: 2px solid $color-primary;
        color: $color-info-dark;
      }
      .btn-out {
        border-radius: $border-radius-3;
        border: none;
        background: $color-primary;
        color: $color-info-light;
      }
      button:disabled {
        background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(19, 1, 1, 0.3)) !important;
        color: light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3)) !important;
        border-color: light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3)) !important;
        pointer-events: none;
        cursor: auto;
      }
    }
    .overflow-y {
      overflow-y: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .forms-container {
      height: 305px;
      background: $color-white;
      border-radius: $border-radius-3;
      display: flex;
      width: 80%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      box-shadow: $box-shadow;
      h3 {
        color: $color-dark;
        font-size: 1.2rem;
        padding: 1rem;
      }
      .timestamp-container {
        display: flex;
        width: 90%;

        flex-direction: column;
        padding: 2rem;
        max-height: 27rem;
        gap: 8px;
        span {
          font-size: 0.8rem;
          text-align: center;
          padding: 0.3rem 0.6rem;
          color: $color-info-dark;
          border-radius: $border-radius-1;
          background: $color-info-light;
          letter-spacing: -0.01em;
          // &:nth-child(odd) {
          //   background: $color-info-light;
          // }
        }
        .week {
          color: $color-light !important;
          background: $color-primary !important;
          font-weight: bold !important;
        }
      }
    }
  }
  .left {
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 100%;
    padding: 1rem;
    overflow: hidden;
    .starts {
      width: 100%;
      justify-content: space-between;
      display: flex;
      padding: 0.8rem;
      gap: 0.5rem;
      .starts__card {
        width: 12rem;
        height: 7rem;
        background: white;
        padding: 0.6rem;
        display: flex;
        gap: 0.5rem;
        flex-direction: column;
        border-radius: $border-radius-3;
        :first-child {
          color: $color-primary;
          font-size: 1.7rem;
        }
        h3 {
          color: $color-dark;
        }
        small {
          color: $color-info-dark;
        }
      }
    }

    form {
      position: relative;
      padding-top: 1.5rem;
      //background: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .search {
        position: relative;
        width: 100%;
        margin: 0 auto;
        background: transparent;
        border-radius: $border-radius-2;
        border: 2px solid $color-info-dark;
        padding: 0.5rem;
        color: #fff;
      }
      .search__icon {
        position: absolute;
        font-size: 2rem;
        margin-right: 0.5rem;
        color: $color-info-dark;
      }
    }
    .welcome-info {
      display: flex;
      flex-direction: column;
      margin: $space-s 0;
      color: $color-info-dark;

      h2 {
        color: $color-dark;
        font-size: $step-0;
      }
      span {
        font-size: $step--1;
      }
    }
    .users-list {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      box-shadow: $box-shadow;
      max-height: 45rem;
      .user-title {
        display: flex;
        justify-content: space-between;
        margin: 1rem 0;
        padding: 0.8rem;
        h3 {
          color: $color-dark;
        }
        select {
          outline: none;
          border-radius: $border-radius-1;
          background: transparent;
          color: $color-info-dark;
        }
      }
      .results {
        background: $color-white;
        border-radius: $border-radius-3;
        padding: $space-xs;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        height: auto;
        max-height: 35rem;
        width: 100%;
        span {
          padding: $space-3xs;
          font-weight: 500;
          width: 98%;
          margin: $space-3xs auto;
          color: $color-info-dark;
          border-radius: $border-radius-1;
          &.active {
            border: 2px solid $color-primary;
          }
          &:focus-within {
            border: 2px solid $color-primary;
          }
          &:hover {
            border: 2px solid $color-primary;
          }
          small {
            opacity: 0.9;
          }
        }
      }
    }
  }
}
@media (max-width: $breakpoint-tablet) {
  .timecard__view {
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr;
    overflow: auto;
  }
}

@media screen and (max-device-width: 1366px) {
  .timecard__view {
    grid-template-columns: 1.6fr 1fr;

    .left {
      .starts {
        gap: 1rem;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        .starts__card {
          width: 100%;
          height: 12rem;
        }
      }
    }
    .right {
      .card {
        height: 30%;
        width: 100%;
        img {
          width: 3.5rem;
          height: 3.5rem;
        }
      }
      .forms-container {
        height: 305px;
        width: 100%;
        .addnew {
          label {
            display: none;
          }
        }
      }
    }
  }
}
