@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900');

.popup-overlay {
    html {
      box-sizing: border-box;
      height: 100%;
    }
    
    *, *:before, *:after {
      box-sizing: inherit;
    }
    
    body {
      background-color: rgb(33, 31, 35);
      background-repeat: no-repeat;
      background: linear-gradient(to right, #7380ec, #201e22);
      background-image: radial-gradient(circle at top, #7380ec 0%, rgba(32, 30, 34, 1) 70%);
      color: #fff;
      font-family: "Poppins", sans-serif;
      font-size: 100%;
      min-height: 100%;
      line-height: 1.5;
      padding: 2.5em 0;
    }
    
    .container {
      margin: 0 auto;
      width: 90%;
      max-width: 1200px;
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
    
    .group:after {
      content: "";
      display: table;
      clear: both;
    }
    
    .grid-1-5 {
      border: 2px solid #363949;
      min-height: 400px;
      padding: 1.25em;
      position: relative;
      text-align: center;
      transition: all 0.2s ease-in-out;
      border-radius: 20px;
    
      @media screen and (min-width: 700px) {
        float: left;
        width: 50%;
        &:nth-child(odd) {
          clear: left;
        }
      }
    
      @media screen and (min-width: 800px) {
        width: 33.3333333%;
        &:nth-child(3n+1) {
          clear: left;
        }
        &:nth-child(odd) {
          clear: none;
        }
      }
    
      @media screen and (min-width: 1120px) {
        width: 20%;
        &:nth-child(odd), &:nth-child(3n+1) {
          clear: none;
        }
      }
    }
    
    .grid-1-5:hover {
      background-color: rgb(83, 69, 91);
      background: linear-gradient(to bottom, #7380ec, #201d22);
      border-top: 2px solid #dce1eb;
      border-bottom: 2px solid #dce1eb;
      box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 1);
      transform: scale(1.025);
      z-index: 2;
      color: #fff !important;
      border-radius: 20px;
      ul,
      p {
        color: #fff !important;
      }
      .button {
        color: #201d22;
        cursor: pointer;
      }
    
      &:before, &:after {
        content: "";
        position: absolute;
        background-color: #dce1eb;
        background: linear-gradient(to bottom, #dce1eb, #dce1eb);
        top: -2px;
        bottom: -2px;
        width: 2px;
      }
    
      &:before {
        left: -2px;
      }
    
      &:after {
        right: -2px;
      }
    
      & .button {
        background-color: #dce1eb;
        background: linear-gradient(to right, #dce1eb, #dce1eb);
      }
    }
    
    h2, h3, p, ul {
      margin: 0;
    }
    
    h2 {
      font-size: 1em;
      font-weight: 400;
      margin: 0 0 0.5em;
    }
    
    h3 {
      font-size: 1.5em;
      letter-spacing: 0.0625em;
      margin: 0 0 0.3333333333em;
    }
    
    p {
      font-size: 0.875em;
    }
    
    p, ul {
      margin: 0 0 1.5em;
    }
    
    ul {
      color: #111e88;
      font-size: 0.75em;
      list-style-type: none;
      padding: 0;
    
      li {
        margin: 0 0 0.8333333333em;
      }
    }
    
    .button {
      background-color: #7380ec;
      border-radius: 20px;
      color: #fff;
      font-size: 1em;
      font-weight: 700;
      padding: 0.75em 1.1em;
      position: absolute;
      bottom: 1.25em;
      left: 50%;
      margin-left: -60px;
      text-decoration: none;
      width: 120px;
    }
    
    .uppercase, .button, h2 {
      text-transform: uppercase;
    }
    
    sup, .small {
      font-size: 0.6125em;
    }
}
