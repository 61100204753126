@import "./variables";

.admin__view {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1.7fr 1fr;
  overflow: hidden;
  .right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    overflow: hidden;
    .card {
      background: $color-white;
      border-radius: $border-radius-3;
      width: 80%;
      margin: 0.5rem auto;
      height: 10rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      gap: 0.3rem;
      box-shadow: $box-shadow;
      img {
        background-color: $color-info-dark;
        border-radius: 50%;
        width: 5rem;
        height: 5rem;
      }
      h3 {
        font-size: 2rem;
        color: #edeffd;
      }
      .card-info {
        border-radius: 0.4rem;
        width: 80%;
        display: flex;
        align-items: center;
        gap: 0.2rem;
        :first-child {
          color: $color-dark;
          background: $color-light;
          padding: 0.1rem $space-3xs;
          height: 100%;
          border-right: 1px solid $color-info-dark;
        }
      }
    }
    .right-manage {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: $space-xs;
      padding: $space-2xs;
      .btn {
        padding: $space-2xs $space-xs;
        border-radius: $border-radius-3;
        font-weight: 700;
      }
      .btn-new {
        background: transparent;
        border: 2px solid $color-primary;
        color: $color-info-dark;
      }
      .btn-del {
        background: $color-danger;
        color: $color-white;
        border: none;
        box-sizing: content-box;
        &:hover {
          background: transparent;
          border: 2px solid $color-danger;
          color: $color-info-dark;
        }
      }
      .active {
        border-radius: $border-radius-3;
        background: $color-primary;
        color: $color-info-light;
      }
    }
    .forms-container {
      background: $color-white;
      border-radius: $border-radius-3;
      box-shadow: $box-shadow;
      flex-grow: 1;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 0.8rem;
      .addnew {
        display: flex;
        width: 80%;
        flex-direction: column;
        gap: $space-3xs;
        padding: $space-2xs;
        .form__status {
          align-self: center;
          color: $color-dark;
          font-weight: 500;
          background-color: $color-danger;
          width: 60%;
          border-radius: $border-radius-1;
          padding: $space-2xs;
          text-align: center;
          transition: 300ms ease;
          &.active {
            background: $color-success !important;
            color: $color-dark;
          }
        }
        .form-group {
          display: flex;
          align-items: center;
          padding: 0.2em 0.4em;
          //background: $color-light;
          gap: 0.4rem;
          border-radius: $border-radius-1;
          label {
            width: 27%;

            color: $color-dark-variant;
            font-weight: 600;
          }

          .inputs {
            width: 100%;
            padding: $space-2xs;
            background: transparent;
            border: 1px solid $color-info-dark;
            border-radius: $border-radius-1;
            outline: none;
            color: $color-dark;
          }
        }
        .checkbox {
          accent-color: $color-primary;
          display: flex;
          align-items: center;
          padding: 0.4rem;
          gap: 0.5rem;
        }
        .btn-submit {
          background: $color-primary;
          border: none;
          padding: $space-2xs;
          font-weight: 600;
          letter-spacing: 0.2rem;
          border-radius: $border-radius-3;
          color: $color-white;
        }
      }
    }
  }
  .left {
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 100%;
    padding: $space-xs;
    overflow: hidden;
    .starts {
      width: 100%;
      justify-content: space-between;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: $space-2xs;
      .starts__card {
        width: 100%;
        height: 11rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr auto auto;
        grid-template-areas:
          "a b c"
          "d e c"
          "f f f";
        box-shadow: $box-shadow;
        background: $color-white;
        padding: $space-xs;

        gap: $space-3xs;
        border-radius: $border-radius-3;
        transition: all 300ms ease;
        .icon__starts {
          grid-area: a;
        }
        h3 {
          grid-area: d;
          color: $color-dark;
        }
        small {
          grid-area: f;
          color: $color-info-dark;
        }
        &:hover {
          box-shadow: none;
        }
        .progress {
          grid-area: c;
          position: relative;
          width: 5rem;
          height: 5rem;
          border-radius: 50%;
          .number {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            small {
              font-size: $step-1;
            }
          }
          svg {
            width: 5rem;
            height: 5rem;
            circle {
              fill: none;
              stroke: $color-primary;
              stroke-width: 9;
              stroke-linecap: round;
              // transform: translate(5px,5px);
              stroke-dasharray: 110;
              stroke-dashoffset: 92;
            }
          }
        }
      }
    }

    form {
      position: relative;
      padding-top: $space-m;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .search {
        position: relative;
        width: 100%;
        margin: 0 auto;
        background: transparent;
        border-radius: 0.8rem;
        border: 2px solid #7d8da1;
        padding: 0.5rem;
        color: #fff;
      }
      .search__icon {
        position: absolute;
        font-size: 2rem;
        margin-right: 0.5rem;
        color: $color-info-dark;
      }
    }
    .welcome-info {
      display: flex;
      flex-direction: column;
      margin: $space-s 0;
      color: $color-info-dark;
      h2 {
        color: $color-dark;
        font-size: $step-0;
      }
      span {
        font-size: $step--1;
      }
    }
    .users-list {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      box-shadow: $box-shadow;
      .user-title {
        display: flex;
        justify-content: space-between;
        margin: $space-xs 0;
        h3 {
          color: $color-dark;
        }
        select {
          outline: none;
          border-radius: $border-radius-1;
          background: transparent;
          color: $color-info-dark;
        }
      }
      .results {
        background: $color-white;
        border-radius: $border-radius-3;
        padding: $space-xs;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        height: auto;
        max-height: 35rem;
        width: 100%;
        span {
          padding: $space-3xs;
          font-weight: 500;
          width: 98%;
          margin: $space-3xs auto;
          color: $color-info-dark;
          border-radius: $border-radius-1;
          &.active {
            border: 2px solid $color-primary;
          }
          &:focus-within {
            border: 2px solid $color-primary;
          }
          &:hover {
            border: 2px solid $color-primary;
          }
          small {
            opacity: 0.9;
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .admin__view {
    height: auto;
    grid-template-columns: 1fr;
    overflow: unset;
    .left {
      width: 100%;
      .starts {
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        .starts__card {
          width: 18rem;
          height: 10rem;
        }
      }
      form {
        width: 95%;
        margin: 0 auto;
      }
      .users-list {
        padding: $space-xs 0;
        width: 90%;
        margin: 0 auto;
      }
    }
    .right {
      width: 100%;
      height: auto;
      .btn-del {
        &:focus {
          background: transparent;
          border: 2px solid $color-danger;
          color: $color-info-dark;
        }
      }
      .forms-container {
        width: 90% !important;
        padding: $space-xs;
        .addnew {
          width: 80%;
        }
      }
    }
  }
}
@media (max-width: $breakpoint-mobile) {
  .admin__view {
    width: 100%;
    grid-template-columns: 1fr;
    .left {
      width: 100%;
      .starts {
        grid-template-columns: 1fr;
        justify-items: center;
        gap: 2rem;
        .starts__card {
          width: 100%;
          height: 10rem;
        }
      }
    }
    .right-manage {
      width: 90%;
      display: flex;
      justify-content: space-between;
      gap: $space-2xs;
      padding: $space-2xs;
      .btn {
        padding: $space-3xs $space-2xs !important;
        border-radius: $border-radius-3;
        font-weight: 700;
      }
      .btn-del {
        &:focus {
          background: transparent;
          border: 2px solid $color-danger;
          color: $color-info-dark;
        }
      }
    }
  }
}

/* ----------- Non-Retina Screens ----------- */
@media screen and (max-device-width: 1366px) {
  .admin__view {
    grid-template-columns: 1.6fr 1fr;

    .left {
      .starts {
        gap: 1rem;
        grid-template-columns: 1fr 1fr 1fr;

        .starts__card {
          width: 100%;
          height: 12rem;
        }
      }
    }
    .right {
      .card {
        height: 30%;
        width: 100%;
        img {
          width: 3.5rem;
          height: 3.5rem;
        }
      }
      .forms-container {
        width: 100%;
        .addnew {
          label {
            display: none;
          }
        }
      }
    }
  }
}
