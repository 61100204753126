@import "./variables";
aside {
  height: 100%;
  background: $color-background;
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  transition: 300ms;
  fieldset {
    border: none;
  }
  legend {
    color: $color-info-dark;
  }

  .top {
    .logo {
      display: flex;
      gap: 0.8rem;
      align-items: center;
      padding-top: 1.4rem;
      padding-left: 1rem;
      color: $color-primary;
      .logo__icon {
        font-size: 2rem;
      }
    }
    .close {
      display: none;
    }
  }
  .side-bar {
    display: flex;
    flex-direction: column;
    height: 45vh;
    position: relative;
    top: 2rem;

    .nav__link {
      display: flex;
      color: $color-info-dark;
      margin-left: 2rem;
      gap: 1rem;
      align-items: center;
      position: relative;
      height: 3.7rem;
      transition: all 300ms ease;
      &:hover {
        margin-left: 1rem;
      }
      &.active {
        background: $color-light;
        color: $color-primary;
        margin-left: 0;
        &:before {
          content: "";
          width: 6px;
          height: 100%;
          background: $color-primary;
        }
      }
    }
    .nav__link__muted {
      display: flex;
      color: $color-info-dark;
      margin-left: 2rem;
      gap: 1rem;
      align-items: center;
      position: relative;
      height: 3.7rem;
      transition: all 300ms ease;
      &:hover {
        // margin-left: 1rem;
      }
    }
  }
  .preference {
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      color: $color-info-dark;
    }

    .content-wrapper {
      padding-left: 2rem;
      height: 3.7rem;
      display: flex;
      align-items: center;
      gap: 3rem;
    }

    .theme-toggler {
      background: $color-light;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 1.2rem;
      width: 4.2rem;
      cursor: pointer;
      border-radius: $border-radius-1;
      span {
        font-size: 1.2rem;
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .active {
        background: $color-primary;
        color: white;
        border-radius: $border-radius-1;
      }
    }
  }
  .aside__footer {
    position: absolute;
    bottom: 2.5rem;
    width: 100%;
    display: flex;
    padding-left: 1rem;

    .profile {
      display: flex;
      text-align: left;
      justify-content: center;
      gap: 0.8rem;
      align-items: center;
    }
    img {
      background-color: $color-info-dark;
      height: 100%;
    }
  }
}
@media (max-width: $breakpoint-tablet) {
  aside {
    position: absolute;
    width: 60%;
    z-index: 11;
    height: 100%;
    display: none;
    // background: hsl(0 0% 0% / 0.75);
    // transform: translateX(-80%);
  }
}
