@import "./variables";

footer {
  background: $color-background;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  color: $color-info-dark;
  font-weight: 500;
  a {
    color: $color-primary;
    font-weight: 600;
  }
}
@media (max-width: $breakpoint-tablet) {
  footer {
    padding: $space-2xs $space-m;
    font-weight: 300;
    font-size: 0.3rem;
    a {
      font-weight: 500;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  footer {
    padding: $space-2xs $space-m;
    h4 {
      font-weight: 300;
      font-size: 0.7rem;
      a {
        font-weight: 500;
        font-size: 0.8rem;
      }
    }
  }
}
