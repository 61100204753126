@import "./variables";

.analytics__view {
  height: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .right {
    display: grid;
    max-height: 35rem;
  }
  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    height: 55%;
    padding: $space-xs;
    overflow: hidden;
    .usercard {
      width: 100%;
      justify-content: space-between;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      justify-items: center;
      padding: $space-xs $space-m;
      gap: $space-2xs;
      .user__card {
        width: 18rem;
        height: 13rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: $box-shadow;
        background: $color-white;
        padding: $space-xs;

        gap: $space-3xs;
        border-radius: $border-radius-3;
        transition: all 300ms ease;
        span {
          font-size: $step-5 !important;
        }
        p {
          font-size: $step-1;
          color: $color-dark;
        }
        small {
          color: $color-info-dark;
        }
        &:hover {
          box-shadow: none;
        }
      }
    }
    form {
      position: relative;
      padding-top: 1.5rem;
      //background: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .search {
        position: relative;
        width: 100%;
        margin: 0 auto;
        background: transparent;
        border-radius: $border-radius-2;
        border: 2px solid $color-info-dark;
        padding: 0.5rem;
      }
      .search__icon {
        position: absolute;
        font-size: 2rem;
        margin-right: 0.5rem;
        color: $color-info-dark;
      }
    }
    .welcome-info {
      display: flex;
      flex-direction: column;
      margin: $space-s 0;
      color: $color-info-dark;
      h2 {
        color: $color-dark;
        font-size: $step-0;
      }
      span {
        font-size: $step--1;
      }
    }
    .users-list {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .user-title {
        display: flex;
        justify-content: space-between;
        margin: $space-xs 0;
        h3 {
          color: $color-dark;
        }
        select {
          outline: none;
          border-radius: $border-radius-1;
          background: transparent;
          color: $color-info-dark;
        }
      }
    }
  }
}
@media (max-width: $breakpoint-tablet) {
  .analytics__view {
    .left {
      .usercard {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        padding-bottom: 2rem;
        .user__card {
          width: 100%;
          height: 10rem;
          justify-items: unset;
          span {
            font-size: $step-3 !important;
          }
          p {
            font-size: $step-0;
          }
        }
      }
    }
    .right {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }
}
@media (max-width: $breakpoint-mobile) {
  .analytics__view {
    max-width: 100vw !important;
    overflow-x: hidden;
    .right {
      display: none;
    }

    .left {
      height: 100%;
      .usercard {
        grid-template-columns: 1fr;
        padding: 0;
        .user__card {
          width: 100%;
          height: 8rem;
          justify-items: unset;
          span {
            font-size: $step-3 !important;
          }
          p {
            font-size: $step--1 !important;
          }
        }
      }
    }
  }
}
