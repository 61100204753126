@import "./variables";

#register {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background-image: url("../assets/bgseiko.png");
  background-size: cover;
  background-position: center;
  background-color: $color-info-dark;
}

.container__register {
  min-width: 20%;
  height: 75% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  color: $color-info-dark;
  border-radius: $border-radius-3;

  h2 {
    color: $color-info-light;
    font-size: $step-2;
    font-weight: 500;
    span {
      font-weight: 300;
      font-size: $step-2;
      color: $color-primary;
    }
  }

  form {
    background: $color-background;
    width: 100%;
    height: 80% !important;
    overflow-y: auto;
    padding: 1rem 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    opacity: 0.98;
    border-radius: $border-radius-1;

    h4 {
      text-align: center;
      color: $color-info-dark;
      font-size: $step--1;
      font-weight: 500;
    }

    label {
      color: $color-info-dark;
      font-size: $step--1;
      font-weight: 500;
    }

    .inputs {
      border: $color-info-dark 1.5px solid;
      width: 100%;
      font-size: $step--2;
      padding: 0.5rem;
      outline: none;
      background: transparent;
      border-radius: $border-radius-1;
      color: $color-info-dark;
    }

    .btn-pry {
      padding: 0.4rem 0.8rem;
      border: none;
      background-color: $color-primary;
      color: white;
      border-radius: $border-radius-1;
      font-weight: 500;
      font-size: $step--1;
      width: 30%;
      align-self: center;
    }

    .submit {
      width: 100%;
    }

    p.misc {
      color: $color-primary;
      font-size: $step--1;
      text-align: center;
    }
  }
}

@media (max-width: 1400px) {
  #register {
    height: 100%;
    .container__register {
      min-width: 30%;
      height: 100%;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  #register {
    align-items: start;
    .container__register {
      padding-top: 30px;
      min-width: 80%;
      height: 100vh !important;
      justify-content: flex-start;
      form {
        height: 83% !important;
      }
    }
  }
}
